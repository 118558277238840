body{
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form{
    background-color: white;
    border-radius: 5px;
    width: 330px;
    margin: 20px auto;
    padding: 20px;
    /* height: 600px; */
}

.form-body{
    text-align: left;
    padding: 20px 10px;
}

.form-body > *{
    padding: 15px;
}

.form__label{
    width: 60%;
}

.form_input{
    width: 60%;
}

.footer{
    text-align: center;
}


.registeration_css {
    height: 1650px;
    width: 700px;
}

@media screen and (max-width: 768px) {
    
    .registeration_css {
        height: 1650px;
        width: 385px;
    }
  }