.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 1px;
    
    cursor: pointer;
    text-decoration: none;
    font-size: 1.0rem;
    display: flex;
    align-items: left;
  }
  .navbar-logo-text {
    color: #fff;
    justify-self: start;
    margin-left: 1px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.0rem;
    display: flex;
    align-items: left;
    width: 200px; /* Set a specific width */
    white-space: nowrap; /* Prevent text from wrapping */
}
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 50px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 2rem;
  }

  .navbar-menu-dis{
    display: none;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    
    .navbar-menu-dis{
      display: block;
    }

    .navbar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 500px;
    }
  
    
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 85vh;
      position: absolute;
      top: 100px; 
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #222423;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      
      
      
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-menu {
      color: #fff;
      margin-left: 15px;
      font-size: 1rem;
      align-items: left;
      margin-top: 0px;
    }

    .navbar-logo {
      color: #fff;
      margin-left: 15px;
      font-size: 1rem;
      align-items: left;
      margin-top: 5px;
    }

    .navbar-logo-text {
      color: #fff;
      justify-self: start;
      margin-left: 35px;
      cursor: pointer;
      text-decoration: none;
      font-size: 1.0rem;
      display: flex;
      align-items: left;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
      
    }
  }