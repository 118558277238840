.timetable{
    text-align:center;
    white-space:pre-wrap;
}

.spaces {
    white-space:pre-wrap;
}

.formtest {
    height: 600px;
}