.testimonials-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .testimonial {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: left;
  }
  
  .testimonial-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .testimonial-text {
    font-style: italic;
    margin: 10px 0;
  }
  
  .testimonial-name {
    font-weight: bold;
    color: #333;
  }