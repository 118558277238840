.header1 {
    margin-left: 150px;
}

.container {
    margin-left: 100px;
    display: grid;
    grid-template-rows: repeat(6, auto);
    grid-gap: 10px; 
}

.container2 {
    padding-top: 1.5%;
    width: auto;    
}

@media screen and (max-width: 768px) {

    .header1 {
        margin-left: 100px;

        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.0rem;
        display: flex;
        align-items: left;
    }

    .container {
        margin-left: 50px;
        padding-top: 5.5%;
        display: grid;
        grid-template-rows: repeat(6, auto);
        width: 390px;
        padding-bottom: 5.5%;
    }

    .container2 {
        padding-top: 1.5%;
        width: 390px;
    }
}