.form{
    background-color: hsla(204, 100%, 2%, 0.926);
    border-radius: 5px;
    margin: 20px auto;
    padding: 20px;
    width: 500px;
    height: 850px;
}

.imgcl{
    width: 450px;
    height: 800px;
}

@media screen and (max-width: 768px) {

    .form{
        background-color: hsla(204, 100%, 2%, 0.926);
        border-radius: 5px;
        margin: 20px auto;
        width: 400px;
        height: 850px;
    }

    .imgcl{
        width: 360px;
        height: 800px;
    }
}